.container {
  background-color: white;
  border-radius: 24px;
  padding: 32px 24px;
  max-width: 680px;
  margin: 0 auto;

  @media (max-width: 966px) {
    padding: 24px 16px;
  }

  & p {
    font-size: 14px;
    line-height: 150%;
    font-weight: 500;
    color: var(--clr-black);
    margin-bottom: 24px;
  }

  & h1 {
    margin-bottom: 16px;
  }
}

.link {
  color: var(--clr-red);
  text-decoration: underline;
}
