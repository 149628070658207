.common {
  position: relative;
  display: flex;

  :global(.MuiInput-underline) {
    &:before,
    &:after {
      content: none;
    }
  }
}

.wrap {
  display: flex;
  flex-flow: column;
}

.tf {
  label {
    position: static;
    transform: none;
  }

  label + :global(.MuiInput-formControl) {
    margin-top: 8px;
  }
}

.counter {
  color: #c4c4c4;
  font-size: 12px;
  margin-top: 8px;
  margin-left: auto;
}

.errorText {
  font-size: 12px;
  color: #ef4444;
  margin-top: 8px;
  flex: 1;
}

.default {
  :global(.MuiInput-root) {
    height: 54px;
    background: white;
    border: 2px solid #e2e2e2;
    border-radius: 8px;
  }

  :global(.MuiInputLabel-root) {
    color: #313131;
  }

  & input {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.outlined {
  :global(.MuiInput-root) {
    height: 54px;
    background: white;
    border: 2px solid #e2e2e2;
    border-radius: 8px;
  }

  & input {
    padding: 0;
  }
}

.second {
  :global(.MuiInput-root) {
    border: 1px solid;
    overflow: hidden;
    border-color: rgba(113, 119, 132, 0.2);
    border-radius: 10px;
    font-size: 14px;
    overflow: hidden;

    input,
    textarea {
      padding: 12px 14px;
    }
  }

  label {
    & + :global(.MuiInputBase-root) {
      margin-top: 10px;
    }
  }

  :global(.MuiInputBase-root.Mui-disabled) {
    background-color: #71778433;
  }
}

.error {
  :global(.MuiInput-root) {
    border: 2px solid #ef4444;
  }

  :global(.MuiInput-root.Mui-focused) {
    border: 2px solid #e2e2e2;
  }
}

.clearButton {
  width: 24px;
  height: 24px;
  border: none;
  outline: none;
  padding: 0;
  margin-right: 14px;
  background-color: transparent;
  cursor: pointer;

  &:disabled {
    & svg path {
      stroke: var(--clr-btn-disabled);
    }
  }
}
