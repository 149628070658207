.visuallyImpairedLabel {
  font-size: 14px;
  line-height: 14px;
  padding-left: 5px;
}

.iconButton {
  padding: 0;
}

@media all and (max-width: 320px) {
  .visuallyImpairedLabel {
    display: none;
  }
}
