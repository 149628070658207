.root {
  --fs-text: 12px;
  --clr-text: inherit;

  font-size: var(--fs-text);
  color: var(--clr-text);
}

.error {
  --clr-text: var(--clr-red);
}
