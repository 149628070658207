.common {
  border-radius: 8px;
  line-height: 14px;
  font-size: 16px;
  font-weight: 400;
  color: white;
}
.small {
  height: 40px;
}

.medium {
  height: 54px;
  min-width: 299px;
}

.text {
  font-family: 'Inter';
  text-transform: none;
}

.text_gray {
  color: var(--clr-black-gray);
}

.text_blue {
  color: #485dc9;
}

.contained_disabled {
  background-color: #bcbcbc !important;
  pointer-events: none;
  color: white;

  &:hover {
    background-color: #bcbcbc !important;
  }
}

.contained_red {
  background-color: var(--clr-red) !important;

  &:hover {
    background-color: var(--clr-red) !important;
  }

  .contained_blue {
    background-color: #485dc9 !important;

    &:hover {
      background-color: var(--clr-blue) !important;
    }

    .contained_gray {
      background-color: var(--clr-gray) !important;

      &:hover {
        background-color: var(--clr-gray) !important;
      }
    }

    .contained_green {
      background-color: var(--clr-green) !important;

      &:hover {
        background-color: var(--clr-green) !important;
      }
    }
  }
}

.contained_blue {
  background-color: #485dc9 !important;

  &:hover {
    background-color: var(--clr-blue) !important;
  }
}

.outlined_gray {
  color: #000;
  background: none;
  border: 2px solid #767676;

  &:hover {
    background-color: white !important;
  }
}

.outlined_white {
  background-color: white !important;
  border: 2px solid #313131;

  &:hover {
    background-color: white !important;
  }
}

.outlined_transparent {
  background-color: white !important;
  border: 2px solid #313131;

  &:hover {
    background-color: white !important;
  }
}

.outlined_red {
  background-color: transparent !important;
  border: 2px solid var(--clr-red) !important;
  color: var(--clr-red) !important;

  font-size: 16px;

  &:hover {
    background-color: transparent !important;
  }
}
