@font-face {
    font-family: 'Proxima Nova Bl';
    src: url('ProximaNova-Black.eot');
    src: local('Proxima Nova Black'), local('ProximaNova-Black'),
        url('ProximaNova-Black.eot?#iefix') format('embedded-opentype'),
        url('ProximaNova-Black.woff2') format('woff2'),
        url('ProximaNova-Black.woff') format('woff'),
        url('ProximaNova-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova Th';
    src: url('ProximaNova-Extrabld.eot');
    src: local('Proxima Nova Extrabold'), local('ProximaNova-Extrabld'),
        url('ProximaNova-Extrabld.eot?#iefix') format('embedded-opentype'),
        url('ProximaNova-Extrabld.woff2') format('woff2'),
        url('ProximaNova-Extrabld.woff') format('woff'),
        url('ProximaNova-Extrabld.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova Lt';
    src: url('ProximaNova-SemiboldIt.eot');
    src: local('Proxima Nova Semibold Italic'), local('ProximaNova-SemiboldIt'),
        url('ProximaNova-SemiboldIt.eot?#iefix') format('embedded-opentype'),
        url('ProximaNova-SemiboldIt.woff2') format('woff2'),
        url('ProximaNova-SemiboldIt.woff') format('woff'),
        url('ProximaNova-SemiboldIt.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Proxima Nova Th';
    src: url('ProximaNovaT-Thin.eot');
    src: local('Proxima Nova Thin'), local('ProximaNovaT-Thin'),
        url('ProximaNovaT-Thin.eot?#iefix') format('embedded-opentype'),
        url('ProximaNovaT-Thin.woff2') format('woff2'),
        url('ProximaNovaT-Thin.woff') format('woff'),
        url('ProximaNovaT-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova Lt';
    src: url('ProximaNova-Light.eot');
    src: local('Proxima Nova Light'), local('ProximaNova-Light'),
        url('ProximaNova-Light.eot?#iefix') format('embedded-opentype'),
        url('ProximaNova-Light.woff2') format('woff2'),
        url('ProximaNova-Light.woff') format('woff'),
        url('ProximaNova-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova Bl';
    src: url('ProximaNova-BlackIt.eot');
    src: local('Proxima Nova Black Italic'), local('ProximaNova-BlackIt'),
        url('ProximaNova-BlackIt.eot?#iefix') format('embedded-opentype'),
        url('ProximaNova-BlackIt.woff2') format('woff2'),
        url('ProximaNova-BlackIt.woff') format('woff'),
        url('ProximaNova-BlackIt.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Proxima Nova Rg';
    src: url('ProximaNova-BoldIt.eot');
    src: local('Proxima Nova Bold Italic'), local('ProximaNova-BoldIt'),
        url('ProximaNova-BoldIt.eot?#iefix') format('embedded-opentype'),
        url('ProximaNova-BoldIt.woff2') format('woff2'),
        url('ProximaNova-BoldIt.woff') format('woff'),
        url('ProximaNova-BoldIt.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Proxima Nova Th';
    src: url('ProximaNova-ThinIt.eot');
    src: local('Proxima Nova Thin Italic'), local('ProximaNova-ThinIt'),
        url('ProximaNova-ThinIt.eot?#iefix') format('embedded-opentype'),
        url('ProximaNova-ThinIt.woff2') format('woff2'),
        url('ProximaNova-ThinIt.woff') format('woff'),
        url('ProximaNova-ThinIt.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Proxima Nova Rg';
    src: url('ProximaNova-Bold.eot');
    src: local('Proxima Nova Bold'), local('ProximaNova-Bold'),
        url('ProximaNova-Bold.eot?#iefix') format('embedded-opentype'),
        url('ProximaNova-Bold.woff2') format('woff2'),
        url('ProximaNova-Bold.woff') format('woff'),
        url('ProximaNova-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova Rg';
    src: url('ProximaNova-RegularIt.eot');
    src: local('Proxima Nova Regular Italic'), local('ProximaNova-RegularIt'),
        url('ProximaNova-RegularIt.eot?#iefix') format('embedded-opentype'),
        url('ProximaNova-RegularIt.woff2') format('woff2'),
        url('ProximaNova-RegularIt.woff') format('woff'),
        url('ProximaNova-RegularIt.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Proxima Nova Th';
    src: url('ProximaNova-ExtrabldIt.eot');
    src: local('Proxima Nova Extrabold Italic'), local('ProximaNova-ExtrabldIt'),
        url('ProximaNova-ExtrabldIt.eot?#iefix') format('embedded-opentype'),
        url('ProximaNova-ExtrabldIt.woff2') format('woff2'),
        url('ProximaNova-ExtrabldIt.woff') format('woff'),
        url('ProximaNova-ExtrabldIt.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Proxima Nova Rg';
    src: url('ProximaNova-Regular.eot');
    src: local('Proxima Nova Regular'), local('ProximaNova-Regular'),
        url('ProximaNova-Regular.eot?#iefix') format('embedded-opentype'),
        url('ProximaNova-Regular.woff2') format('woff2'),
        url('ProximaNova-Regular.woff') format('woff'),
        url('ProximaNova-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova Lt';
    src: url('ProximaNova-LightIt.eot');
    src: local('Proxima Nova Light Italic'), local('ProximaNova-LightIt'),
        url('ProximaNova-LightIt.eot?#iefix') format('embedded-opentype'),
        url('ProximaNova-LightIt.woff2') format('woff2'),
        url('ProximaNova-LightIt.woff') format('woff'),
        url('ProximaNova-LightIt.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Proxima Nova Lt';
    src: url('ProximaNova-Semibold.eot');
    src: local('Proxima Nova Semibold'), local('ProximaNova-Semibold'),
        url('ProximaNova-Semibold.eot?#iefix') format('embedded-opentype'),
        url('ProximaNova-Semibold.woff2') format('woff2'),
        url('ProximaNova-Semibold.woff') format('woff'),
        url('ProximaNova-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Galiver Sans';
    src: url('GaliverSans-Italic.eot');
    src: local('Galiver Sans Italic'), local('GaliverSans-Italic'),
        url('GaliverSans-Italic.eot?#iefix') format('embedded-opentype'),
        url('GaliverSans-Italic.woff2') format('woff2'),
        url('GaliverSans-Italic.woff') format('woff'),
        url('GaliverSans-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Galiver Sans';
    src: url('GaliverSans-BoldItalic.eot');
    src: local('Galiver Sans Bold Italic'), local('GaliverSans-BoldItalic'),
        url('GaliverSans-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('GaliverSans-BoldItalic.woff2') format('woff2'),
        url('GaliverSans-BoldItalic.woff') format('woff'),
        url('GaliverSans-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Galiver Sans';
    src: url('GaliverSans.eot');
    src: local('Galiver Sans'), local('GaliverSans'),
        url('GaliverSans.eot?#iefix') format('embedded-opentype'),
        url('GaliverSans.woff2') format('woff2'),
        url('GaliverSans.woff') format('woff'),
        url('GaliverSans.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Galiver Sans';
    src: url('GaliverSans-Bold.eot');
    src: local('Galiver Sans Bold'), local('GaliverSans-Bold'),
        url('GaliverSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('GaliverSans-Bold.woff2') format('woff2'),
        url('GaliverSans-Bold.woff') format('woff'),
        url('GaliverSans-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('Inter-Regular.eot');
    src: url('Inter-Regular.eot?#iefix') format('embedded-opentype'),
    url('Inter-Regular.woff2') format('woff2'),
    url('Inter-Regular.woff') format('woff'),
    url('Inter-Regular.ttf') format('truetype'),
    url('Inter-Regular.svg#Inter-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('Inter-Light.woff2') format('woff2'),
    url('Inter-Light.woff') format('woff'),
    url('Inter-Light.ttf') format('truetype'),
    url('Inter-Light.svg') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('Inter-Medium.eot');
    src: url('Inter-Medium.eot?#iefix') format('embedded-opentype'),
    url('Inter-Medium.woff2') format('woff2'),
    url('Inter-Medium.woff') format('woff'),
    url('Inter-Medium.ttf') format('truetype'),
    url('Inter-Medium.svg#Inter-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('Inter-SemiBold.eot');
    src: url('Inter-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('Inter-SemiBold.woff2') format('woff2'),
    url('Inter-SemiBold.woff') format('woff'),
    url('Inter-SemiBold.ttf') format('truetype'),
    url('Inter-SemiBold.svg#Inter-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('Inter-Bold.eot');
    src: url('Inter-Bold.eot?#iefix') format('embedded-opentype'),
    url('Inter-Bold.woff2') format('woff2'),
    url('Inter-Bold.woff') format('woff'),
    url('Inter-Bold.ttf') format('truetype'),
    url('Inter-Bold.svg#Inter-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('Inter-ExtraBold.eot');
    src: url('Inter-ExtraBold.eot?#iefix') format('embedded-opentype'),
    url('Inter-ExtraBold.woff2') format('woff2'),
    url('Inter-ExtraBold.woff') format('woff'),
    url('Inter-ExtraBold.ttf') format('truetype'),
    url('Inter-ExtraBold.svg#Inter-ExtraBold') format('svg');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}



@font-face {
    font-family: 'Montserrat';
    src: url('Montserrat-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('Montserrat-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('Montserrat-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('Montserrat-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('Montserrat-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('Montserrat-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('Montserrat-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('Montserrat-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('Roboto-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('Roboto-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Roboto';
    src: url('Roboto-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Roboto';
    src: url('Roboto-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('Roboto-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Grandis Extended';
    src: url('GrandisExtended-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Grandis Extended';
    src: url('GrandisExtended-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Grandis Extended';
    src: url('GrandisExtended-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Grandis Extended';
    src: url('GrandisExtended-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Grandis Extended';
    src: url('GrandisExtended-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-display: block; /* Шрифт нестандартный по размерам в отличие от других. Поведение загрузки специально такое! Иначе начинает прыгать в период загрузки*/
    font-family: 'Vasek';
    src: url('VasekItalic.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

