.root {
  align-items: center;
}

.variantAdmin {
  align-items: center;

  :global(.MuiTypography-root) {
    font-size: 12px;
  }
}
