.root {
  --fs-label: 16px;
  --fs-helper-text: 12px;
  --fw-label: 500;
  --clr-label: #3f3f3f;
  --clr-helper-text: #c4c4c4;

  position: static;
  color: var(--clr-label);
  font-size: var(--fs-label);
  font-weight: var(--fw-label);
  transform: none;

  mark {
    background: none;
    color: var(--clr-red);
  }

  h5 {
    margin-top: 4px;
    color: var(--clr-helper-text);
    font-size: var(--fs-helper-text);
    font-weight: normal;
    line-height: 1.33;
  }
}

:global(.dark) .root {
  --clr-label: var(--clr-pale);
}

.variantAdmin {
  --fw-label: 400;
  --fs-label: 12px;
  --clr-label: #717784;
}
