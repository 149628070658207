.datepicker {
  display: flex;
  justify-content: center;
  height: 54px;
  padding: 16px;
  border: 2px solid #e2e2e2;
  border-radius: 8px;
  background: white;

  :global(.MuiInputBase-root) {
    &:before,
    &:after {
      content: none;
    }
  }
}

.errorText {
  font-size: 12px;
  color: #ef4444;
  margin-top: 8px;
}

.error {
  border: 2px solid #ef4444;
}

.error:has(input[type='text']:focus) {
  border: 2px solid #e2e2e2;
}
