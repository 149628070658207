.registerWrapper {
  width: 100%;
  max-width: 680px;
  padding: 32px 24px;
  background-color: white;
  border-radius: 24px;
  margin-right: auto;
  position: relative;
  z-index: 2;
}

@media screen and (max-width: 980px) {
  .registerWrapper {
    padding: 24px 16px;
    box-shadow: none;
    max-width: initial;
  }
}
