.root {
  display: flex;
  gap: 4px;
}

.btn {
  width: 32px;
  height: 32px;
  border-width: 1px;
  border-style: solid;
  font-weight: 700;

  &.active {
    border-bottom-width: 3px;
  }
}
