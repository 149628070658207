.root {
  --clr-text: inherit;
  color: var(--clr-text);
}

.variantUnderline {
  font-family: Montserrat;
  font-weight: 600;
  text-decoration: underline;
  text-decoration-thickness: 2px;
  text-underline-offset: 8px;
  line-height: 2;
}

.variantUnderline_colorRed {
  --clr-text: var(--clr-red);
}

.variantUnderline_colorBlue {
  font-family: 'Inter';
  font-weight: 700;
  --clr-text: var(--clr-title-blue);
  text-decoration-line: underline;
  text-underline-offset: 4px;
}

.arrow {
  display: inline-block;
  margin-left: 0.5em;
}

.disabled {
  pointer-events: none;
}
