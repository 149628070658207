.root {
  display: flex;
  flex-flow: column;
  font-family: Montserrat;
  background-color: white;
  border-radius: 24px;
  padding: 32px 24px;
}

.title {
  font-family: 'Grandis Extended';
  font-size: 34px;
  font-weight: 900;
  order: 1;
  color: #313131;
  margin-bottom: 24px;
  text-transform: uppercase;
}

.form {
  display: grid;
  gap: 24px;
  padding-bottom: 24px;
  order: 2;

  & button {
    font-size: 16px;
    &:disabled {
      color: white;
    }
  }
}

.politics {
  color: #717784;
  font-size: 14px;
  line-height: 1.5;

  a {
    text-decoration: none;
    color: var(--clr-blue-light);
  }
}

.redirectLink {
  display: flex;
  justify-content: center;
  color: #808080;
  font-family: Inter;
  font-size: 16px;
  order: 3;

  a {
    color: #313131;
    font-weight: 500;
    text-decoration: none;
  }
}

.form button:last-child {
  margin-top: -8px;
}

@media all and (max-width: 960px) {
  .root {
    padding: 24px 16px;
  }

  .title {
    font-size: 24px;
  }

  .form {
    order: 3;
    border-bottom: none;
  }
}
