.panelWrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99999999;
  border-bottom: 2px solid gray;
  padding: 8px;
}

.panel {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: max-content;
  max-width: 100%;
  margin: 0 auto;
}

.panelBlock {
  display: flex;
}

.btn {
  width: 32px;
  height: 32px;
  border-width: 1px;
  border-style: solid;
}

.collapseContent {
  padding-top: 32px;
}
