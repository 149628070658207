@import 'tailwindcss/base';

@layer base {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-bold;
  }
}

@import 'tailwindcss/components';

@import 'tailwindcss/utilities';

input[type='number'] {
  -moz-appearance: textfield;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

:root {
  --h1-size: 32px;
  --h2-size: 24px;
  --h5-size: 18px;
  --counter-size: 12px;
  --clr-green: #32f5db;
  --clr-green-500: #008d7a;
  --clr-black-gray: rgb(49 49 49);
  --clr-red: rgb(239 68 68);
  --clr-red-500: #f15053;
  --clr-blue: rgb(72 93 201);
  --clr-blue-light: #3a98ef;
  --clr-telegram: #229ed9;
  --clr-blue-900: #080b0e;
  --clr-mako: #3b3f43;
  --clr-ebony: #0f172a;
  --clr-gray-light: #f0f0f0;
  --clr-gray-100: #d3d3d3;
  --clr-gray-400: 113 119 132;
  --clr-gray-800: #282828;
  --clr-gray-950: #0f0f0f;
  --clr-gray: rgb(188 188 188);
  --clr-pale: #dbdfe8;
  --clr-error: #fbd3d4;
  --clr-violet: #9cade5;
  --clr-purple: #b675ac;
  --clr-green-400: #49c555;
  --clr-banner-light: #f8f8f8;
  --clr-white-2: #fcfcfc;
  --clr-btn-default: #485dc9;
  --clr-btn-hover: #ef4444;
  --clr-btn-disabled: #bcbcbc;
  --clr-title-blue: #485dc9;
  --clr-cianide-red: #ef4444;
  --clr-white: #fff;
  --clr-black: #313131;
  --clr-bg: #2c2c2c;
  --clr-paper-dark: rgb(15 23 42 / 75%);
  --clr-paper-gray: rgb(255 255 255 / 60%);
  --clr-counter: #c4c4c4;
  --clr-input-border: #e2e2e2;
  --clr-admin-label: #717784;
  --clr-admin-visible-green: #297e37;
  --clr-admin-visible-red: #e2251d;
}

html {
  @apply text-black-gray;
}

/** react-error-overlay fix */
/* ломает экспорт в пдф */
/* body > iframe:not([id]) {
  display: none;
} */

*,
:after,
:before {
  box-sizing: border-box;
}

*::-webkit-scrollbar {
  width: 4px;
}

*::-webkit-scrollbar-track {
  background-color: white;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--clr-red);
  /*outline: 1px solid slategrey;*/
}

@media all and (max-width: 960px) {
  :root {
    --h1-size: 27px;
    --h5-size: 16px;
  }
}

h1 {
  font-size: var(--h1-size);
}

h2 {
  font-size: var(--h2-size);
}

h5 {
  font-size: var(--h5-size);
}

html {
  height: 100%;
  font-weight: 400;
}

body {
  background-color: #fff !important;
  display: flex;
  flex-direction: column;
}

* {
  -webkit-overflow-scrolling: touch;
  margin: 0;
}

html.fslightbox-open > body {
  padding-right: 17px;
}

.between {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /*height: 100%; */
  /*height: -webkit-fill-available*/
  width: 100%;
  position: relative;
}

.content,
.contentAuto {
  flex: 1 0 auto;
  background: #fff;
}

.content {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.content > div {
  flex: 1;
}

.contentAuto > div {
  height: auto;
}

.d-flex {
  display: flex;
}

[data-code='true'] > div > input {
  letter-spacing: 24px;
  font-size: 18px;
}

[data-notempty='true'] > div {
  background: #e8f0fe;
}

.activeGroupButton {
  background: #e8f0fe !important;
}

.MuiPickersDay-daySelected {
  background-color: #272c5b;
}

#mgo-mcw-chat-button .mgo-mcw__button-bg {
  background-color: #f95356 !important;
}

#mgo-mcw-vk-button .mgo-mcw__button-bg {
  background-color: #0077ff !important;
}

.mgo-mcw__group-buttons_state-hover.mgo-mcw__group-buttons_state-horizontal:before {
  opacity: 0.1 !important;
}

.mgo-mcw_color-green .mgo-mcw__chat-send-button {
  fill: #7595ff !important;
}
.mgo-mcw_color-green .mgo-mcw__chat-send-button:hover {
  fill: #4c71ea !important;
}

.mgo-mcw_color-green .mgo-mcw__chat-message_my .mgo-mcw__chat-message-text {
  background-color: #7595ff !important;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

.text-lines-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.text-lines-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.text-lines-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.text-lines-4 {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
}

/* виджет usedesk */
@media screen and (max-width: 960px) {
  .uw__messenger-layout__buttons {
    transform: translateY(-60px);
  }
}



.lk-toast .Toastify__toast {
  padding-left: 20px !important;
  border-radius: 5px !important;
  overflow: hidden;
}

.lk-toast .Toastify__close-button {
  z-index: 100;
}

.lk-toast .Toastify__close-button path {
  fill: white;
}

.lk-toast .Toastify__toast--success {
  background: var(--clr-black-gray) !important;
  color: var(--clr-green) !important;
}

.lk-toast .Toastify__toast--success .Toastify__progress-bar {
  background-color: rgba(39, 44, 91, 0.1) !important;
}

.lk-toast.Toastify__toast-container {
  z-index: 999999999 !important;
}
