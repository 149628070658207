.root.root {
  background: #0077ff !important;

  &:hover {
    background: #0077ff !important;
  }
}

.loader {
  display: flex;
  justify-content: center;
}
