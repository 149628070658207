.paper {
  width: 100%;
  max-width: 680px;
  padding: 32px;
  border-radius: 8px;
}

.paperFullScreen {
  padding: 0;
  max-width: 100%;
}

.contentContainerFullScreen {
  height: 100%;
}

.closeIcon {
  position: absolute;
  top: 32px;
  right: 24px;
  color: #d3d3d3;

  width: 18px;
  height: 18px;

  svg {
    width: 30px;
    height: 30px;

    > * {
      fill: var(--clr-black);
    }
  }
}

@media all and (max-width: 960px) {
  .root .paper {
    max-width: 95%;
    padding: 32px 24px;
    margin: 24px 0;
  }

  .rootFullscreen .paperFullScreen {
    max-width: 95%;
    height: initial;
    margin: 24px 0;
    padding: 32px 24px;
  }
}
