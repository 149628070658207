.root {
  background-color: white;
  border-radius: 24px;
  padding: 32px 24px;

  & form {
    display: flex;
    flex-direction: column;
    gap: 24px;

    & button {
      min-width: 100%;
      height: 40px;
      font-size: 16px;

      &:disabled {
        color: white;
      }
    }
  }
}

.title {
  font-family: 'Grandis Extended';
  font-size: 34px;
  font-weight: 900;
  color: #313131;
  margin-bottom: 24px;
  text-transform: uppercase;
}

.acceptText {
  color: #313131;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin-top: 16px;

  & a {
    color: #313131;
    text-decoration: underline;
  }
}

@media all and (max-width: 960px) {
  .root {
    padding: 24px 16px;
  }

  .title {
    font-size: 24px;
  }
}
