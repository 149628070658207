.title,
.titleSecondVar,
.titleCodeEnter {
  font-family: 'Grandis Extended';
  font-size: 34px;
  font-weight: 900;
  line-height: 110%;
  color: #313131;
  margin-bottom: 24px;
  text-transform: uppercase;
}

.titleSecondVar {
  font-size: 28px;
}

.titleCodeEnter {
  margin-bottom: 16px;
}

.repeatCode {
  font-family: 'Proxima Nova Rg';
  font-size: 16px;
  line-height: 19px;
  color: #bcbcbc;
  margin-bottom: 24px;
  display: inline-block;
  width: 100%;
  cursor: pointer;
}

.error {
  font-family: 'Inter';
  font-weight: normal;
  font-size: 12px;
  line-height: 19px;
  color: #ef4444;
  display: inline-block;
  transform: translateY(-16px);
}

.promptText {
  font-size: 16px;
  margin-bottom: 24px;
  font-weight: 500;
  color: #313131;
}

.input {
  margin-bottom: 24px;
}

.button {
  font-size: 16px;
  &:disabled {
    color: white;
  }
}

.code[data-notempty='true'] > div {
  background: initial;
}

:global(.titleFieldSet) {
  font-size: 16px;
  margin-bottom: 8px;
  color: #313131;
}

@media all and (max-width: 960px) {
  .root {
    padding: 24px 16px;
  }

  .title,
  .titleSecondVar,
  .titleCodeEnter {
    font-size: 24px;
  }
}
